import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'popper.js';
import 'bootstrap';
// Import Swiper and modules
import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    Autoplay, EffectFade
}
from 'swiper/swiper.esm.js';
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, EffectFade]);


$(document).ready(function () {
    "use strict";

    //=================================== WEITERLEITUNG ZU URL PER ONCLICK-EVENT AUF DIV =====================================//
    $("li[data-href],div[data-href]").on("click tap", function () {
        if (typeof $(this).attr("data-target") !== "undefined") {
            window.open($(this).attr("data-href"), $(this).attr("data-target"));
        } else {
            window.location.href = $(this).attr("data-href");
        }
    });

    //=================================== Scroll to top =====================================//
    $('.scrollUpButton-JS').on("click tap", function () {
        var duration = 800;
        $('html, body').animate({
            scrollTop: 0
        }, duration);
        return false;
    });




    //=================================== Swiper-Slider Startseite=====================================//
    if ($('.swiperBlock_1').length) {
        var mySwiper = new Swiper('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 800,
            effect: 'slide',
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }

        });

    }
    
    
     //a für 3 Bilder nebeneinander in Desktop
    var a = {
            direction: "horizontal",
            slidesPerView: 3,
            centeredSlides: !0,
            spaceBetween: 30,
            grabCursor: !0,
            initialSlide: 1,
            breakpoints: {
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                    initialSlide: 0
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 3
                }
            },
            loop: !0
        };
    
    if ($('.swiperBlock_2').length) {
        var sa = new Swiper('.swiperBlock_2', a);
    }

    //=================================== BS Popup =====================================//
    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover'
        })
    })

    //=================================== Google-Maps =====================================//
    if (document.getElementById("contactMap")) {

        var renderContactMap = function (mapOptions, container) {
            var map = {};
            map.latlng = new google.maps.LatLng(52.12012, 11.6089845);
            map.options = mapOptions;
            map.options.center = map.latlng;
            map.symbol = {
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: "#2A2",
                scale: 7,
                strokeWeight: 2,
                fillOpacity: 100,
                fillColor: "#FFF"
            };
            map.map = new google.maps.Map(document.getElementById(container), map.options);
            map.marker = new google.maps.Marker({
                position: map.latlng,
                title: "AKM",
                icon: map.symbol
            });
            map.infowindow = new google.maps.InfoWindow({
                content: '<div style="width: 170px ;">AKM Arzt- und Krankenhaus-<br>bedarf Vertriebs GmbH</div>'
            });
            map.marker.setMap(map.map);
            map.infowindow.open(map.map, map.marker);
        };

        var mapOptions = {
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            minZoom: 10,
            draggable: false,
            scrollwheel: false,
            disableDoubleClickZoom: true
        };
        renderContactMap(mapOptions, "contactMap");
    }


    //=================================== Parsley-Formular =====================================//
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            };
        })
        return o;
    };

    $(function () {

        $('.parsleyForm').submit(function (e) {
            e.preventDefault();
            var form = $(this);
            if (form.parsley().isValid()) {
                var posting = $.post("/real/user/mod/kontaktform.php", JSON.stringify({
                    data: $(this).serializeObject(),
                    action: 'submitForm'
                }));
                posting.done(function (data) {
                    $('#contactForm').toggle();
                    $('#contactFormSuccess').toggle();
                    $('#contactFormSuccess .btnCustomer').one('click', function () {
                        form.parsley().reset();
                        $('.invalid-form-error-message').empty();
                        document.getElementById("inlineForm").reset();
                        $('#contactFormSuccess').toggle();
                        $('#contactForm').toggle();
                    })
                    console.log("success");
                });
                posting.fail(function (data) {
                    console.log("fail");
                });
                posting.error(function (data) {
                    console.log(data);
                });
                console.log("end");
            }
            return false;
        });
        if ($('.catalogueForm').length > 0) {
            $('.catalogueForm').parsley().on('form:validate', function (formInstance) {
                if (!formInstance.isValid({
                        group: 'catalogue',
                        force: true
                    })) {
                    $('.invalid-form-error-message')
                        .html('Bitte wählen Sie einen Katalog aus!')
                        .toggleClass('filled', false);
                    formInstance.validationResult = false;
                    return false;
                }
            })
        }
    });

});
